export const LAZYLOAD_ON = 'on';
export const LAZYLOAD_OFF = 'off';
export const LAZYLOAD_IGNORE = 'ignore';
export const LAZYLOAD_SSRONLY = 'ssrOnly';
export const LAZYLOAD_WHEN_IDLE = 'whenIdle';
export const LAZYLOAD_WHEN_VISIBLE = 'whenVisible';
export const STATIC_ITEM = 'STATIC_ITEM';
export const STATIC_ICON = 'STATIC_ICON';
export const STATIC_IMAGE = 'STATIC_IMAGE';
export const STATIC_MEDIA = 'STATIC_MEDIA';
export const STATIC_ILLUSTRATION = 'STATIC_ILLUSTRATION';
export const ITEMS_IMAGES_GRID = 'ITEMS_IMAGES_GRID';
export const ALL_ITEMS_IMAGES = '4';
export const TYPE_PRICE = 'price';
export const TYPE_ICON = 'icon';
export const INTERPOLATION_REGEXP = /{([\s\S]+?)}/g;
export const DISCOUNT_FONT_SIZE = 10;
export const DYNAMIC_ACCESS_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LONG: 'long',
  NEW_LONG: 'new-long',
};
export const DYNAMIC_ACCESS_MODAL = ['payment_methods'];
export const DYNAMIC_ACCESS_CUSTOM_CARD = ['location'];
export const CARD_CUSTOM_IFRAME_MODAL = ['payment_methods'];
export const SVG_SPRITES = 'svg-sprites-icons';
export const DEVICE_TYPE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};
export const DEEPLINK_HOME = 'meli://home';
export const LAZYLOAD_THRESHOLD = 6;

